<!-- 员工登录 -->
<template>
  <div class="login-container">
    <div class="contain">
      <div class="con-left">
        <img class="logo-img" src="../../assets/logo.png" alt="帮客" />
        <span class="platName">帮客智能客服管理系统</span>
        <span class="plat">{{ comName }}</span>
        <span class="plat">v1.0</span>
      </div>
      <div class="con-right">
        <span class="line"></span>
        <span class="wel"
          >登录账号
          <span @click="() => { $router.push({path:'/pers-signUp',query:{comId: this.comId,comName: this.comName}}) }" class="log"
            >注册账号</span
          >
        </span>
        <!-- 输入框区域 -->
        <el-form ref="form" :model="form" :rules="formRules">
          <el-form-item prop="username">
            <el-input
              v-model="form.username"
              maxlength="11"
              prefix-icon="iconfont icon-shoujihao"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.passWord"
              type="password"
              prefix-icon="iconfont icon-mima"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
        </el-form>
        <div
          class="login-btn"
          @click="
            () => {
              signUp();
            }
          "
        >
          登录
        </div>
        <!-- <div class="agree">注册登录代表您接受《帮客智能客服管理系统用户协议》及《隐私协议》</div> -->
        <div class="login-tip">有问题联系管理员</div>
      </div>
    </div>
  </div>
</template>
<script>
import { loginFromCustomer } from "../../service/login.js";
import { Config } from "../../utils/index.js";
export default {
  name: "persLogin",
  components: {},
  data() {
    return {
      isFirst: true,
      fromRoute: this.$route.query.fromRoute,
      form: {
        username: "", //账号 （手机号）
        passWord: ""
      },
      comId: this.$route.query.comId, //公司ID 
      comName: this.$route.query.comName, //公司名
      formRules: {
        username: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            pattern: /(^1[3|4|5|6|7|8|9]\d{9}$)/,
            message: "手机号格式不对",
            trigger: "blur"
          }
        ]
      },
      timeShow: true, //倒计时
      time: 60
    };
  },
  destroyed() {
    window.removeEventListener("keydown", this.loginPress, false);
  },
  mounted() {
    //绑定事件
    window.addEventListener("keydown", this.loginPress);
  },
  methods: {
    loginPress(e) {
      if (e.keyCode == 13) {
        this.signUp();
      }
    },
    async signUp() {
      let params = {
        username: this.form.username,
        passWord: this.form.passWord
      };
      params.companyId = this.$route.query.comId;
      let loginData = await loginFromCustomer(params);
      if (loginData.code == 200) {
        if (loginData.data && loginData.data.token) {
          const Authorization =
            loginData.data.tokenHead + " " + loginData.data.token;
          this.$sto.set(Config.store.token, Authorization);
        }
        if (this.fromRoute) {
          window.location.href = this.fromRoute;
        } else {
          this.$router.push({ path: "/index" });
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
  background-color: #ebf0ff;
  background-image: url("../../assets/images/login_background.png");
  // background-size: 2020px 1100px;
  // background-position: 230px center;
  background-repeat: no-repeat;
  .contain {
    display: flex;
    align-items: center;
    height: 100%;
    .con-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 535px;
      height: 751px;
      background-image: url("../../assets/images/login_left.png");
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 201px 0 32px;
      .logo-img {
        width: 138px;
      }
      .platName {
        font-size: 40px;
        color: #fff;
        margin-top: 38px;
      }
      .plat {
        font-size: 24px;
        color: #fff;
        &:last-child {
          margin-top: 192px;
        }
      }
    }
    .con-right {
      box-sizing: border-box;
      width: 535px;
      height: 751px;
      padding: 0 44px;
      text-align: left;
      background: #fff;
      border-radius: 0 8px 8px 0;
      .line {
        display: block;
        width: 40px;
        height: 6px;
        margin-top: 80px;
        margin-bottom: 8px;
        background: #1890ff;
        border-radius: 8px;
      }
      .wel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;
        font-size: 40px;
        color: #333333;
        .log {
          font-size: 20px;
          color: #999;
          &:hover {
            color: #1890ff;
            cursor: pointer;
          }
        }
      }
      .user {
        width: 32px;
        height: 32px;
      }
      .error {
        font-size: 18px;
        color: #f00;
        margin-bottom: 16px;
      }
      .el-form {
        margin-top: 64px;
      }
      /deep/ .el-form-item {
        position: relative;
        .sms {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 120px;
          height: 48px;
          border-radius: 4px;
          background: #1890ff;
          span {
            font-size: 16px;
            color: #fff;
          }
        }
      }
      /deep/ .el-input__inner {
        height: 48px;
        border: 1px solid rgba(187, 187, 187, 100);
        border-radius: 8px;
        padding-left: 60px;
        font-size: 16px;
        color: #999999;
      }
      /deep/.el-input__prefix {
        .iconfont {
          width: 18px !important;
          height: 18px !important;
          font-size: 24px !important;
          position: absolute;
          top: 8px;
          left: 19px;
          color: #999999;
        }
      }
      .form-item {
        margin-top: 32px;
        position: relative;
        /deep/ .sms {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 120px;
          height: 48px;
          border-radius: 4px;
          background: #1890ff;
          span {
            font-size: 16px;
            color: #fff;
          }
        }
        .iconfont {
          width: 18px !important;
          height: 18px !important;
          font-size: 24px !important;
          position: absolute;
          top: 6px;
          left: 19px;
          color: #999999;
        }
        .login-input {
          padding: 12px 80px;
          width: 100%;
          height: 48px;
          box-sizing: border-box;
          outline: 0;
          font-size: 18px !important;
          border: 1px solid #999999;
          border-radius: 8px;
          background: #fff !important;
          &:hover {
            border: 1px solid #4a76ff;
          }
          &::-webkit-input-placeholder {
            color: rgba(118, 118, 118, 0.65);
          }
        }
      }
      .login-btn {
        width: 100%;
        height: 48px;
        line-height: 48px;
        font-size: 18px;
        color: #fff;
        text-align: center;
        background-color: #1890ff;
        background-size: 100% 100%;
        border-radius: 8px;
        margin-top: 64px;
        cursor: pointer;
      }
      .login-tip {
        text-align: center;
        font-size: 16px;
        color: #b7b7b7;
        margin-top: 122px;
      }
    }
  }
}
</style>
